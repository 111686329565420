import { usePage } from '@inertiajs/react';
import clsx from 'clsx';
import React from 'react';
import { IconEmphasisButton } from '../button/IconEmphasisButton';
import { IconName } from '../icon/Icon';
import { sortBy } from 'lodash';

interface Props {
  className?: string;
}

export function NavGlobal({ className }: Props) {
  const page = usePage();
  const rmsBaseUrl = (page.props.config as any).rms_base_url;
  function buildRedirectUrl(path: string): string {
    return `/rms/redirect?url=${rmsBaseUrl}${path}`;
  }
  const navButtons = sortBy(
    [
      {
        href: buildRedirectUrl('/ar/login.php'),
        prefixIcon: 'music-dj',
        label: 'A&R Jukebox'
      },
      {
        href: route('artist.index'),
        prefixIcon: 'artist-group',
        label: 'Artists'
      },
      {
        href: '/artists/table?columns=a_r,advertising,agent,analytics,artist,community,digital_marketing,distribution,label_status,legal,manager,marketing_manager,name,production,publicity&filter%5Bis_nettwerk%5D=1&page=1&per_page=100',
        prefixIcon: 'tree-structure',
        label: 'Artist Assignments'
      },
      {
        external: false,
        href: '/brand-guidelines',
        prefixIcon: 'edit',
        label: 'Brand Guidelines'
      },
      {
        external: false,
        href: route('party.index'),
        prefixIcon: 'user-group',
        label: 'Collaborators'
      },
      {
        href: buildRedirectUrl('/contract_reports/list_label_contracts.php'),
        prefixIcon: 'contract-single',
        label: 'Label Contracts'
      },
      {
        href: buildRedirectUrl('/contract_reports/release_browser/'),
        prefixIcon: 'songwriting',
        label: 'Label Copy'
      },
      {
        href: buildRedirectUrl('/contract_reports/project/'),
        prefixIcon: 'discs',
        label: 'Project Portal'
      },
      {
        href: buildRedirectUrl('/contract_reports/list_publishing_contracts.php'),
        prefixIcon: 'news',
        label: 'Publishing Contracts'
      },
      {
        href: buildRedirectUrl('/contract_reports/report/'),
        prefixIcon: 'chart-line',
        label: 'Reports'
      },
      {
        href: buildRedirectUrl('/contract_reports/project/resources.php'),
        prefixIcon: 'features-list',
        label: 'Resources'
      },
      {
        href: buildRedirectUrl('/contract_reports/schedule/'),
        prefixIcon: 'calendar',
        label: 'Schedule'
      },
      {
        href: buildRedirectUrl('/contract_reports/report/report.php?report_id=135'),
        prefixIcon: 'search-list',
        label: 'Song Search'
      },
      {
        href: buildRedirectUrl('/contract_reports/tools.php'),
        prefixIcon: 'maintenance',
        label: 'Tools'
      },
      {
        href: buildRedirectUrl('/contract_reports/project/calendar_tour.php'),
        prefixIcon: 'travel',
        label: 'Tour Dates'
      },
      {
        href: buildRedirectUrl('/contract_reports/video/'),
        prefixIcon: 'video',
        label: 'Videos'
      }
    ],
    ({ label }) => label
  ).map((navItem, key) => <StyledIconEmphasisButton key={key} {...navItem} />);

  return (
    <nav
      className={clsx(
        'grid grid-flow-row gap-y-2 md:grid-cols-2 md:gap-x-4 md:gap-y-6 xl:grid-flow-col',
        Math.ceil(navButtons.length / 3) === 6 ? 'xl:grid-rows-6' : 'xl:grid-rows-5',
        className
      )}
    >
      {navButtons}
    </nav>
  );
}

const StyledIconEmphasisButton = ({
  label,
  prefixIcon,
  href,
  external = true
}: {
  href: string;
  label: string;
  prefixIcon: IconName;
  external?: boolean;
}) => {
  return (
    <IconEmphasisButton
      rounding="square"
      href={href}
      external={external}
      className="w-full bg-interactive-on-dark md:w-fit md:bg-transparent"
      label={label}
      align="left"
      prefixIcon={prefixIcon}
    />
  );
};
